<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-descriptions
      title="审核信息"
      :column="1"
      v-if="coachInfo.admin_id"
      class="m-b20"
    >
      <el-descriptions-item label="审核人">
        {{ coachInfo.admin_name }}
      </el-descriptions-item>
      <el-descriptions-item label="审核状态">
        <el-tag type="success" v-if="coachInfo.status == 1">审核通过</el-tag>
        <el-tag type="info" v-if="coachInfo.status == 0">待审核</el-tag>
        <el-tag type="danger" v-if="coachInfo.status == 2">审核拒绝</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="审核时间">
        {{ coachInfo.update_time }}
      </el-descriptions-item>
      <el-descriptions-item label="审核说明">
        {{ coachInfo.remark }}
      </el-descriptions-item>
    </el-descriptions>

    <el-row v-if="coachInfo.id">
      <el-col :span="12">
        <el-descriptions title="用户信息" :column="1">
          <el-descriptions-item
            :labelStyle="{ display: 'flex', alignItems: 'center' }"
            label="头像"
          >
            <div class="image-wrap">
              <el-image
                style="width: 100px; height: 100px; border-radius: 0.5rem"
                fit="cover"
                :src="coachInfo.head_portrait || CoachList.head_portrait"
              >
              </el-image>
              <div
                class="mask-img"
                @click="cropImg(coachInfo.head_portrait, 'head_portrait')"
              >
                <i class="el-icon-edit"></i>
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="姓名">
            <span>{{ CoachList.real_name }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="姓别">
            <span>{{ CoachList.sex == 1 ? "男" : "女" }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="教龄"
            >{{ coachInfo.teaching_age }}年</el-descriptions-item
          >
          <el-descriptions-item label="教学类别">
             <p v-if="coachInfo.teaching_categories">{{coachInfo.teaching_categories}}</p>
            <p v-if="coachInfo.partner_categories">{{coachInfo.partner_categories}}</p>
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{
            coachInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="地址"
            >{{ CoachList.province }}-{{ CoachList.city }}-{{
              CoachList.district
            }}-{{ CoachList.address }}</el-descriptions-item
          >
          <el-descriptions-item label="授课类型">
            <span v-for="(item, index) in CoachList.teaching_type" :key="index">
              {{ Classtype[item] + " " }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="服务距离">
            <p v-if="CoachList.distance_service">
              {{ CoachList.distance_service + "公里" }}
            </p>
            <p v-else>不限</p>
          </el-descriptions-item>
          <el-descriptions-item label="服务人群">
            <span
              v-for="(item, index) in CoachList.people_service"
              :key="index"
              >{{ crowd[item] || "" }}</span
            >
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="12">
        <el-descriptions title="资质信息" :column="1">
          <el-descriptions-item label="资格证书">
            <div class="flex">
              <div
                v-for="(item, index) in coachInfo.certificate_pic"
                :key="index"
              >
                <div class="image-wrap">
                  <el-image :key="index" class="pic" :src="item" />
                  <div
                    class="mask-img"
                    @click="cropImg(item, 'certificate_pic', index)"
                  >
                    <i class="el-icon-edit"></i>
                  </div>
                </div>

                <p style="text-align: center">
                  {{
                    certificateMap[coachInfo.class_certificate[index].trim()]
                  }}
                </p>
              </div>
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="是否毕业">
            <div v-if="coachInfo.is_graduate === true">已毕业</div>
            <div v-if="coachInfo.is_graduate === false">未毕业</div>
          </el-descriptions-item>
          <el-descriptions-item label="毕业院校">
            {{ coachInfo.college }}
          </el-descriptions-item>
          <el-descriptions-item label="院校证书">
            <div class="flex">
              <div v-for="(item, index) in coachInfo.college_img" :key="index">
                <el-image
                  :key="index"
                  class="pic"
                  :src="item"
                  :preview-src-list="coachInfo.college_img"
                />
              </div>
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="荣誉资质" v-if="coachInfo.honor_imgs">
            <div class="flex">
              <div
                style="margin-right: 10px"
                v-for="(item, index) in coachInfo.honor_imgs"
                :key="index"
              >
                <div class="image-wrap">
                  <el-image class="pic" :src="item" />
                  <div
                    class="mask-img"
                    @click="cropImg(item, 'honor_imgs', index)"
                  >
                    <i class="el-icon-edit"></i>
                  </div>
                </div>

                <p
                  style="text-align: center"
                  v-if="coachInfo.honor_qualification"
                >
                  {{ coachInfo.honor_qualification.split(",")[index] }}
                </p>
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <div class="part-title">执教经历</div>
    <div class="flex flex-wrap" v-if="record.length > 0">
      <div class="record" v-for="item in record" :key="item.id">
        <el-descriptions :column="1" border>
          <el-descriptions-item
            label="执教机构"
            :labelStyle="{ 'min-width': '120px' }"
          >
            <span>{{ item.institution }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="执教时间"
            >{{ item.start_time.split(" ")[0] }}-{{
              item.end_time.split(" ")[0]
            }}</el-descriptions-item
          >
          <el-descriptions-item label="执教职位">{{
            item.position
          }}</el-descriptions-item>
          <el-descriptions-item label="执教内容">{{
            item.content
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div v-else class="noInfo">暂无信息</div>

    <div v-if="areaList.length">
      <div class="s-line"></div>
      <div
        style="
          font-size: 26px;
          color: #333333;
          margin-top: 30px;
          margin-bottom: 30px;
        "
      >
        教练场地
      </div>
      <div class="flex flex-wrap">
        <div
          style="margin: 0 20px 20px 0"
          v-for="item in areaList"
          :key="item.id"
        >
          <el-descriptions :column="1" border>
            <el-descriptions-item label="场馆名称">
              <span>{{ item.name }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="场馆地址">{{
              item.address
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
    <div class="btns flex" v-if="coachInfo.status == 0">
      <el-button type="success" @click="update(1)">通过</el-button>
      <el-button
        type="danger"
        @click="dialogVisible = true"
        style="margin-left: 20px"
        >拒绝</el-button
      >
    </div>
    <el-dialog title="拒绝理由" :visible.sync="dialogVisible" width="30%">
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入内容"
        v-model="remark"
      >
      </el-input>
      <div
        v-for="(item, index) in list"
        :key="index"
        @click="remark = item"
        style="margin-top: 10px; margin-right: 10px"
      >
        {{ item }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="update(2)">确 定</el-button>
      </span>
    </el-dialog>

    <imageCropper ref="crop" @success="cropSuccess"></imageCropper>
  </div>
</template>

<script>
import imageCropper from "../../components/imageCropper/imageCropper.vue";

export default {
  components: {
    imageCropper,
  },
  data() {
    return {
      zt: 1,
      coach_id: "",
      attestation_record_id: "",
      coachInfo: {},
      nav: { firstNav: "教练管理", secondNav: "教练认证信息" },
      record: [],
      certificateMap: {
        1: "初级证书",
        2: "中级证书",
        3: "高级证书",
        4: "特级证书",
      },
      certificate: [
        {
          name: "",
        },
        {
          name: "初级",
          id: 1,
        },
        {
          name: "中级",
          id: 2,
        },
        {
          name: "高级",
          id: 3,
        },
        {
          name: "特级",
          id: 4,
        },
      ],
      crowd: {
        1: "成人",
        2: "学龄前",
        3: "学龄",
        4: "亲子",
      },
      Classtype: {
        1: "到店",
        2: "上门",
        3: "自带场馆",
        4: "陪练",
      },
      CoachList: {},
      areaList: [],
      dialogVisible: false,
      remark: "",
      list: [],
      admin: JSON.parse(localStorage.getItem("managerInfo")),

      cropImgOption: {
        origin: "",
        index: 0,
        name: "",
        path: "",
      },
    };
  },
  created() {
    this.coach_id = this.$route.query.coach_id;
    this.attestation_record_id = this.$route.query.id;
    if (this.coach_id) {
      this.getCoach();
      this.getCoachRecode();
      this.CoachUser();
      this.getarea();
    }
  },
  methods: {
    cropSuccess(v) {
       this.cropImgOption.path = v;
        let data = {
          id:this.attestation_record_id
       }
        if( this.coachInfo[this.cropImgOption.name] instanceof Array){
            this.coachInfo[this.cropImgOption.name].splice(this.cropImgOption.index,1,this.cropImgOption.path)
            data[this.cropImgOption.name] = this.coachInfo[this.cropImgOption.name].join(',')
        }else{
            this.coachInfo[this.cropImgOption.name] = this.cropImgOption.path
            data[this.cropImgOption.name] = this.cropImgOption.path
        }
        this.$post("/user/coachAttestationRecord/update", data).then((res) => {
       
      });
    },
    cropImg(img, name, index) {
      let option = {};
      if (name == "head_portrait") {
        option.fixedNumber = [1,1];
      }
      this.cropImgOption.origin = img;
      this.cropImgOption.name = name;
      this.cropImgOption.index = index || 0;
      this.$refs.crop.open(img, option);
    },
    getarea() {
      this.$get("user/mechanism/venue/query", {
        coach_id: this.coach_id,
      }).then((res) => {
        this.areaList = res.data.data;
      });
    },
    CoachUser() {
      this.$get("user/coach/findById", {
        id: this.coach_id,
      }).then((res) => {
        if (res.data.code == 0) {
          this.CoachList = res.data.data;
          this.CoachList.teaching_type =
            this.CoachList.teaching_type.split(",");
          this.CoachList.people_service =
            this.CoachList.people_service.split(",");
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //更新状态
    update(status) {
      if(status == 2 && !this.remark){
        this.$message('请输入拒绝理由')
        return
      }


      let url = "/user/coachAttestationRecord/update";
      let data = {
        id: this.attestation_record_id,
        coach_id: this.coach_id,
        status,
        remark: this.remark || null,
        admin_id: this.admin.id,
        admin_name: this.admin.name,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.$router.back();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //教练认证信息
    getCoach() {
      let url = "/user/coachAttestationRecord/findById";
      let params = {
        id: this.attestation_record_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          let info = res.data.data;
          info.college_img && (info.college_img = info.college_img.split(","));
          info.honor_imgs = info.honor_qualification_imgs
            ? info.honor_qualification_imgs.split(",")
            : [];
          info.certificate_pic = info.certificate_pic
            ? info.certificate_pic.split(",")
            : [];
          this.coachInfo = info;
          this.coachInfo.class_certificate =
            info.class_certificate.split(",") || [];
        });
    },
    //执教经历
    getCoachRecode() {
      let url = "/user/coachTeachingRecord/queryListPage";
      let params = {
        attestation_record_id: this.attestation_record_id,
        coach_id: this.coach_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.record = res.data.data;
        });
    },
  },
};
</script>

<style>
.cell {
  text-align: center;
}

.pic {
  width: 100px;
  height: 100px;
}

.btns {
  padding: 10px 0 20px 30px;
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
}

.record {
  box-sizing: border-box;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.part-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.image-wrap {
  position: relative;
}

.mask-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(7, 7, 7, 0.3);
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
}

.image-wrap:hover .mask-img {
  display: flex;
}
.s-line{
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 10px 0 20px;
}
</style>
